import React from "react"
import PropTypes from "prop-types"
// import { Stack, Typography, Box, Modal } from "@mui/material"
import { Stack, Typography, Box } from "@mui/material"
import { GatsbyImage } from "gatsby-plugin-image"

import { FaTwitter, FaLinkedinIn } from "react-icons/fa"

const Founder = ({ data }) => {
  const {
    title,
    name,
    job_title,
    linked_in,
    twitter,
    description,
    image,
    // embed_video_url,
  } = data

  return (
    <Box
      component="section"
      width="100vw"
      position="relative"
      minHeight="20vh"
      left="50%"
      right="50%"
      ml="-50vw"
      mr="-50vw"
      bgcolor="#FBFBFF"
      // sx={{
      //   background: "linear-gradient(75.64deg, #000065 0.87%, #000057 100%)",
      // }}
      py={{ base: 6, md: 8 }}
    >
      <Typography
        color="primary"
        component="h2"
        variant="h2"
        textAlign="center"
        mb={{ md: 8 }}
        px={2}
      >
        {title}
        <Typography color="secondary" component="span" variant="h2">
          .
        </Typography>
      </Typography>
      <Stack
        maxWidth="1200px"
        width="100%"
        py={4}
        px={2}
        direction={{ base: "column", lg: "row-reverse" }}
        mx="auto"
        justifyContent="center"
        alignItems={{ base: "center" }}
        spacing={{ base: 6, lg: 16 }}
      >
        {/* Profile Image */}
        <Box
          width={{ base: "150px", lg: "260px" }}
          flexShrink={{ lg: 0 }}
          mr={{ lg: 7 }}
          borderRadius="50%"
          position="relative"
        >
          <Box width="100%" height="90%" position="relative" zIndex={2}>
            <GatsbyImage
              image={image.childImageSharp.gatsbyImageData}
              alt="Sylvan Ravinet"
              style={{
                borderRadius: "50%",
                maxHeight: "100%",
                maxWidth: "100%",
              }}
            />
          </Box>
          <Box
            position="absolute"
            height="104%"
            width="104%"
            top="-2%"
            left="-2%"
            borderRadius="50%"
            zIndex="1"
            bgcolor="#FBFBFB"
            // sx={{
            //   background:
            //     "linear-gradient(75.64deg, #000065 0.87%, #000057 100%)",
            // }}
          />
          <Box
            position="absolute"
            height="110%"
            width="110%"
            bgcolor="#000065"
            top="-5%"
            left="-5%"
            borderRadius="50%"
            zIndex="0"
          />
          {/* <VideoModal video_url={embed_video_url} /> */}
        </Box>
        {/* Text */}
        <Stack
          direction="column"
          spacing={{ base: 1, lg: 2 }}
          color="white!important"
        >
          {/* Founder Name */}
          <Typography
            fontWeight="bold"
            fontSize={{ base: "1.25rem", lg: "1.75rem" }}
            lineHeight="1.5rem"
            color="primary"
          >
            {name}
          </Typography>
          {/* Founder Job title */}
          <Typography
            fontSize="1.125rem"
            lineHeight="1.375rem"
            fontWeight="300"
            color="primary"
          >
            {job_title}
          </Typography>
          {/* Icons */}
          <Stack direction="row" spacing={2} py={1}>
            <IconContainer href={twitter}>
              <FaTwitter size="17px" color="#fff" />
            </IconContainer>
            <IconContainer href={linked_in}>
              <FaLinkedinIn size="17px" color="#fff" />
            </IconContainer>
          </Stack>
          {/* Divider */}
          <Box
            w="100%"
            borderBottom="0.5px solid #fff"
            display={{ base: "none", lg: "initial" }}
            pt="0.7rem"
          />
          {/* Founder Text */}
          <Typography fontStyle="italic" pt="0.5rem" color="primary">
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}

Founder.propTypes = {
  data: PropTypes.object.isRequired,
}

const IconContainer = ({ children, href }) => (
  <Box
    bgcolor="#000065"
    component="a"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    borderRadius="50%"
    width="30px"
    height="30px"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    {children}
  </Box>
)

// const PlayButton = ({ onClick }) => (
//   <Box
//     component="button"
//     onClick={onClick}
//     position="absolute"
//     bottom={{ base: "-12%", lg: 0 }}
//     border="none"
//     borderRadius="50%"
//     pt="3px"
//     pl="2px"
//     left={{ base: "50%", lg: "110%" }}
//     width="50px"
//     height="50px"
//     zIndex={3}
//     sx={{
//       transform: "translateX(-50%)",
//       cursor: "pointer",
//       transition: "0.5s ease-in-out",
//       opacity: 1,
//       "&:hover": {
//         opacity: 0.6,
//       },
//     }}
//   >
//     <Box
//       position="absolute"
//       top={0}
//       left={0}
//       bottom={0}
//       right={0}
//       bgcolor="#fff"
//       zIndex={3}
//       borderRadius="50%"
//       display="flex"
//       alignItems="center"
//       justifyContent="center"
//     >
//       <svg
//         width="13"
//         height="17"
//         viewBox="0 0 11 15"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           fillRule="evenodd"
//           clipRule="evenodd"
//           d="M1.125 1.875L9.875 7.5L1.125 13.125V1.875Z"
//           fill="#000065"
//           stroke="#000065"
//           strokeWidth="2"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//       </svg>
//     </Box>

//     <Box
//       position="absolute"
//       borderRadius="50%"
//       bgcolor={{ base: "#241C52", lg: "#E8E8E8" }}
//       left="-20%"
//       top="-20%"
//       width="140%"
//       height="140%"
//       zIndex={2}
//       sx={{ opacity: 0.3 }}
//     />
//   </Box>
// )

// const modalStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   maxWidth: "800px",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
//   width: "100%",
//   height: "100%",
//   maxHeight: "500px",
//   "& iframe": {
//     left: 0,
//     top: 0,
//     width: "100%",
//     height: "100%",
//     position: "absolute",
//   },
// }

// const VideoModal = ({ video_url }) => {
//   const [open, setOpen] = React.useState(false)
//   const handleOpen = () => setOpen(true)
//   const handleClose = () => setOpen(false)

//   return (
//     <>
//       <PlayButton onClick={handleOpen} />
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={modalStyle}>
//           <iframe
//             width="853"
//             height="480"
//             src={video_url}
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//             allowFullScreen
//             title="Embedded youtube"
//           />
//           <CloseModalButton handleClose={handleClose} />
//         </Box>
//       </Modal>
//     </>
//   )
// }

export default Founder

// const CloseModalButton = ({ handleClose }) => (
//   <Box
//     component="button"
//     fontSize={20}
//     onClick={handleClose}
//     sx={{
//       position: "absolute",
//       top: "-35px",
//       right: "-38px",
//       width: "35px",
//       height: "35px",
//       cursor: "pointer",
//       backgroundColor: "transparent",
//       border: "none",
//       // "@media only screen and (max-width: 860px)": {
//       //   right: 0,
//       // },
//       "&:before, &:after": {
//         content: '""',
//         position: "absolute",
//         height: "2px",
//         width: "100%",
//         top: "50%",
//         left: "0",
//         backgroundColor: "#fff",
//         borderRadius: "5px",
//         marginTop: "-6px",
//       },
//       "&:before": {
//         transform: "rotate(45deg)",
//       },
//       "&:after": {
//         transform: "rotate(-45deg)",
//       },
//     }}
//   />
// )
