import React from "react"
import PropTypes from "prop-types"
import { Stack, Typography, Box } from "@mui/material"
import LocalizedLink from "../LocalizedLink"
import { Buttons } from ".."
import { BlogPostGrid } from ".."

const BlogSection = ({ posts, data }) => {
  const blogPosts = posts.nodes
  const { title, subtitle, button } = data

  return (
    <Stack
      component="section"
      bgcolor="#FBFBFF"
      width="100vw"
      position="relative"
      left="50%"
      right="50%"
      ml="-50vw"
      mr="-50vw"
    >
      <Box
        maxWidth="1200px"
        width="100%"
        px={2}
        py={{ base: 6, md: 8 }}
        mx="auto"
      >
        <Box textAlign="center" mb={{ base: 2, md: 4 }}>
          <Typography color="primary" component="h2" variant="h2" px={2} mb={4}>
            {title}{" "}
            <Typography color="secondary" component="span" variant="h2">
              ?
            </Typography>
          </Typography>
          <Typography>{subtitle}</Typography>
        </Box>
        <BlogPostGrid posts={blogPosts} showMore />
        <Box display="flex" justifyContent="center" pt={4}>
          <LocalizedLink to="/free-access" style={{ textDecoration: "none" }}>
            <Buttons.Primary title={button} isLink />
          </LocalizedLink>
        </Box>
      </Box>
    </Stack>
  )
}

BlogSection.propTypes = {
  data: PropTypes.object.isRequired,
  posts: PropTypes.object.isRequired,
}

export default BlogSection
