import React from "react"
import PropTypes from "prop-types"
import { Box, Stack, Typography } from "@mui/material"
import { GatsbyImage } from "gatsby-plugin-image"
import { Buttons } from ".."
import LocalizedLink from "../LocalizedLink"

const About = ({ data }) => {
  const { title, subtitle, button_title, button_link, items } = data

  return (
    <Stack
      direction={{ base: "column", md: "row-reverse" }}
      alignItems="center"
      component="section"
      py={{ base: 6, md: 8 }}
      px={2}
    >
      <Box mb={{ base: 4, md: 0 }} pl={{ md: 8 }}>
        <Typography
          component="h2"
          variant="h2"
          textAlign={{ base: "center", md: "left" }}
          color="primary"
          mb={3}
        >
          {title.blue}{" "}
          <Typography component="span" variant="h2" color="secondary">
            {title.red}
          </Typography>
        </Typography>
        <Typography textAlign={{ base: "center", md: "left" }} mb={{ md: 4 }}>
          {subtitle}
        </Typography>
        <Box display={{ base: "none", md: "block" }}>
          <LocalizedLink to={button_link} style={{ textDecoration: "none" }}>
            <Buttons.Secondary title={button_title} />
          </LocalizedLink>
        </Box>
      </Box>
      <ItemGrid items={items} />
      <Box display={{ base: "block", md: "none" }} mt={4}>
        <LocalizedLink to={button_link} style={{ textDecoration: "none" }}>
          <Buttons.Secondary title={button_title} />
        </LocalizedLink>
      </Box>
    </Stack>
  )
}

About.propTypes = {
  data: PropTypes.object.isRequired,
}

const ItemGrid = ({ items }) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      gridTemplateRows="minmax(min-content, max-content)"
      gridTemplateAreas='"item-0 item-0""item-1 item-2"'
    >
      {items.map((item, index) => (
        <Container
          flex={1}
          w="100%"
          key={item.icon.id + index}
          title={item.title}
          text={item.subtitle}
          icon={item.icon}
          gridArea={`item-${index}`}
        />
      ))}
    </Box>
  )
}

ItemGrid.propTypes = {
  items: PropTypes.array.isRequired,
}

const Container = ({ title, icon, text, children, ...props }) => (
  <Box
    boxShadow="0px 0px 10px rgba(0, 0, 101, 0.1)"
    borderRadius="10px"
    px={2}
    py={3}
    m={1}
    flex={1}
    w="100%"
    {...props}
  >
    <GatsbyImage
      image={icon.childImageSharp.gatsbyImageData}
      alt={title}
      style={{ maxWidth: "2.5rem", marginBottom: "0.5rem" }}
    />
    <Typography
      component="h5"
      fontSize="1rem"
      lineHeight="1.1875rem"
      fontWeight="bold"
      color="primary"
      fontFamily="Lato"
      mb={1}
    >
      {title}
    </Typography>
    <Typography fontSize="0.875rem" lineHeight="1.1875rem" color="grey">
      {text}
    </Typography>
  </Box>
)

export default About
