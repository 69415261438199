export async function fetchPostJSON(url, data) {
  try {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST",
      // mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      //   credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      body: JSON.stringify(data || {}), // body data type must match "Content-Type" header
    }).then(async res => {
      const body = await res.json()

      if (body.url) {
        window.location.href = body.url
      }

      return body
    })
    return response // parses JSON response into native JavaScript objects
  } catch (err) {
    throw new Error(err)
  }
}

// export async function fetchGetJSON(url) {
//   try {
//     const data = await fetch(url).then(res => res.json())
//     return data
//   } catch (err) {
//     throw new Error(err.message)
//   }
// }
