import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"

const LocalizedSeo = ({
  config,
  langKey,
  title,
  description,
  banner,
  pathname,
  article,
}) => {
  const {
    buildTime,
    siteMetadata: {
      defaultBanner,
      languages,
      author,
      logo,
      pathPrefix,
      siteUrl,
      twitter,
    },
  } = config

  const intl_config = {
    languages,
    ...config.siteMetadata[langKey], // spread i18n default title/description based on current route
  }

  const seo = {
    title:
      (title && `${title} | ${intl_config.titleAlt}`) ||
      intl_config.defaultTitle,
    description: description || intl_config.defaultDescription,
    image: banner || `${siteUrl}${defaultBanner}`,
    url: `${siteUrl}${pathname || "/"}`,
  }
  const realPrefix = pathPrefix === "/" ? "" : pathPrefix

  let schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      "@id": siteUrl,
      url: siteUrl,
      name: intl_config.defaultTitle,
      alternateName: intl_config.titleAlt || "",
    },
  ]

  if (article) {
    schemaOrgJSONLD = [
      {
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        "@id": seo.url,
        url: seo.url,
        name: seo.title,
        alternateName: intl_config.titleAlt || "",
        headline: seo.title,
        image: {
          "@type": "ImageObject",
          url: seo.image,
        },
        description: seo.description,
        datePublished: buildTime,
        dateModified: buildTime,
        author: {
          "@type": "Person",
          name: author,
        },
        publisher: {
          "@type": "Organization",
          name: author,
          logo: {
            "@type": "ImageObject",
            url: siteUrl + realPrefix + logo,
          },
        },
        isPartOf: siteUrl,
        mainEntityOfPage: {
          "@type": "WebSite",
          "@id": siteUrl,
        },
      },
    ]
  }

  return (
    <>
      <Helmet title={seo.title} htmlAttributes={{ lang: langKey }}>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta
          name="apple-mobile-web-app-title"
          content={intl_config.shortName}
        />
        <meta name="application-name" content={intl_config.shortName} />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>

        {/* OpenGraph  */}
        <meta property="og:url" content={seo.url} />
        <meta property="og:type" content={article ? "article" : null} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitter} />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
      </Helmet>
    </>
  )
}

LocalizedSeo.propTypes = {
  config: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

LocalizedSeo.defaultProps = {
  config: null,
  title: null,
  description: null,
  banner: null,
  pathname: null,
  article: false,
}

export default LocalizedSeo
