import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import markdownStyles from "../styles/markdown-styles"

const ContentWrapper = ({ title, subtitle, children, markdownHTML }) => {
  return (
    <Box component="section" mx={2} py={8}>
      <Box mx="auto" mb={4}>
        <Typography
          textAlign="center"
          component="h1"
          variant="h4"
          color="primary"
          mb={3}
        >
          {title}
          <Typography component="span" color="secondary" variant="h4">
            .
          </Typography>
        </Typography>
        {subtitle && (
          <Typography fontSize={{ md: "1.125rem" }}>{subtitle}</Typography>
        )}
      </Box>
      {children}
      {markdownHTML && <MarkdownContainer markdownHTML={markdownHTML} />}
    </Box>
  )
}

ContentWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  markdownHTML: PropTypes.string,
}

export default ContentWrapper

export const MarkdownContainer = ({ markdownHTML }) => (
  <Box
    dangerouslySetInnerHTML={{ __html: markdownHTML }}
    color="#1B1A3C"
    sx={markdownStyles}
  />
)
MarkdownContainer.propTypes = {
  markdownHTML: PropTypes.string,
}
