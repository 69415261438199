import React from "react"

import PropTypes from "prop-types"
import { useForm } from "react-hook-form"

import { Box, FormControl, Typography } from "@mui/material"

import { Buttons } from ".."

const inputStyle = {
  border: "0.3px solid rgba(0, 0, 101, 0.3)",
  backgroundColor: "#F7F7FB",
  borderRadius: "2px",
  padding: "1rem",
  marginBottom: "1rem",
}

const LoginContainer = ({ data }) => {
  const { register, handleSubmit } = useForm()

  const onSubmit = data => {
    return null
  }

  const { email, password, button } = data

  return (
    <Box
      boxShadow="0px 5px 30px rgba(0, 0, 101, 0.2)"
      borderRadius="20px"
      width="100%"
      maxWidth="570px"
      mx="auto"
      p={4}
      mb={6}
    >
      <FormControl
        component="form"
        sx={{ width: "100%" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography fontWeight="bold" color="#464646" mb="0.3rem">
          {email}*
        </Typography>
        <Box
          component="input"
          sx={inputStyle}
          {...register("email", {
            pattern:
              /* eslint-disable no-console, no-control-regex*/
              /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
            required: true,
          })}
        />
        <Typography fontWeight="bold" color="#464646" mb="0.3rem">
          {password}*
        </Typography>
        <Box
          component="input"
          type="password"
          sx={inputStyle}
          {...register("password", { required: true })}
        />
        <Buttons.Secondary
          type="submit"
          title={button}
          style={{
            minWidth: "200px",
            alignSelf: "center",
            marginTop: "1.5rem",
          }}
        />
      </FormControl>
    </Box>
  )
}

LoginContainer.propTypes = {
  data: PropTypes.shape({
    button: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
}

export default LoginContainer
