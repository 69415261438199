import React, { useEffect } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
// import SelectLanguage from "../components/SelectLanguage"
import { Box, Stack, Typography, Popper, AppBar } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"
import { Buttons } from "../components"
import { Button } from "@mui/material"
import LocalizedLink from "../components/LocalizedLink"
import useScrollTrigger from "@mui/material/useScrollTrigger"

const NavItems = ({ items }) =>
  items.map((item, index) => {
    return (
      <LocalizedLink
        key={`nav_item_${index}`}
        to={item.link}
        style={{ textDecoration: "none" }}
      >
        <Typography
          color="#fff"
          component="span"
          sx={{
            "&:hover": {
              fontWeight: "bold",
            },
          }}
        >
          {item.title}
        </Typography>
      </LocalizedLink>
    )
  })

//////
function ElevationScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    color: trigger ? "primary" : "transparent",
    transition: trigger ? "0.3s" : "0.5s",
  })
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}
/////

const HeaderHome = ({ langs, url, nav_items, matchingSlug, props }) => {
  let localStorage
  const storageKey = "userLangPreference"

  if (typeof window !== `undefined`) {
    localStorage = window.localStorage
  }

  // Redirect to user selected language preference, fetched from localStorage
  useEffect(() => {
    // Get user preference from localStorage
    if (typeof window !== `undefined`) {
      const userLangPreference = localStorage.getItem(storageKey)

      if (userLangPreference) {
        // This finds the correct page link to redirect to from the lang array with the user set language in localStorage
        const userLangUrl = langs.find(
          item => item.langKey === userLangPreference
        ).link

        // If the current is unequally the user-set preference, redirect to that page
        if (url !== userLangUrl) {
          navigate(userLangUrl, { replace: true })
        }
      }
    }
  }, [localStorage, langs, url])

  return (
    <ElevationScroll {...props}>
      <AppBar component="header">
        <Stack
          mx="auto"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          maxWidth="1200px"
          width="100%"
          px={2}
          py={2}
          spacing={2}
        >
          {/* logo + menu mobile */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            spacing={2}
            mr={{ base: 1, md: 0 }}
            sx={{
              flexGrow: 1,
            }}
          >
            <MobileMenu
              langs={langs}
              url={url}
              nav_items={nav_items}
              matchingSlug={matchingSlug}
              storageKey={storageKey}
            />
            <LocalizedLink to="/">
              <Box pl={1} maxWidth={{ base: "140px", md: "180px" }}>
                <StaticImage
                  src="../../static/assets/logo-wt.png"
                  alt="Cyberbrief logo"
                />
              </Box>
            </LocalizedLink>
          </Box>
          {/* Nav + Ctas */}
          <Stack direction="row" alignItems="center" spacing={3} flexShrink="0">
            {/* Nav */}
            <Stack
              direction="row"
              component="nav"
              alignItems="center"
              spacing={2}
              flexShrink="0"
              display={{ base: "none", md: "flex" }}
            >
              <NavItems items={nav_items.links} />
              {/* <SelectLanguage
            langs={langs}
            url={url}
            matchingSlug={matchingSlug}
            storageKey={storageKey}
          /> */}
            </Stack>
            {/* Ctas */}
            <Stack direction="row" alignItems="center" spacing={3}>
              {/* Login Button */}
              <LocalizedLink
                to="https://app.cyberbrief.fr/"
                style={{ textDecoration: "none" }}
              >
                <Stack direction="row">
                  <Box
                    display="flex"
                    mr={{ base: "0px", md: "5px" }}
                    alignSelf="center"
                  >
                    <LoginIcon />
                  </Box>
                  <Typography
                    display={{ base: "none", md: "flex" }}
                    color="#fff"
                    sx={{
                      "&:hover": {
                        fontWeight: "bold",
                        textDecoration: "underline #fff",
                      },
                    }}
                  >
                    {nav_items.button_secondary}
                  </Typography>
                </Stack>
              </LocalizedLink>
              {/* Desktop Subscribe Button */}
              <Box display={{ base: "none", md: "flex" }}>
                <LocalizedLink
                  to="/subscribe"
                  style={{ textDecoration: "none" }}
                >
                  <Buttons.HeaderBtn2 title={nav_items.button_primary} />
                </LocalizedLink>
              </Box>
              {/* Mobile Subscribe Button */}
              <Box display={{ base: "initial", md: "none" }} flexShrink={"0"}>
                <LocalizedLink
                  to="/subscribe"
                  style={{ textDecoration: "none" }}
                >
                  <MobileNavButton title={nav_items.button_primary} />
                </LocalizedLink>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </AppBar>
    </ElevationScroll>
  )
}

// Mobile Menu

const MobileMenu = ({
  langs,
  url,
  nav_items,
  matchingSlug = null,
  storageKey,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popper" : undefined

  return (
    <>
      <Box
        display={{ base: "inline-block", md: "none" }}
        component="button"
        border="none"
        bgcolor="transparent"
        pb="3px"
        onClick={handleClick}
      >
        <BurgerIcon />
      </Box>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        aria-describedby={id}
        type="button"
        style={{
          position: "relative",
          zIndex: "30",
        }}
      >
        <Box
          display={{ md: "none" }}
          py={3}
          px={2}
          bgcolor="#000065"
          boxShadow="0px 4px 7px rgba(0, 0, 101, 0.1)"
          mt={2}
          width="100vw"
        >
          <Stack spacing={2}>
            <NavItems items={nav_items.links} />
            {/* <SelectLanguage
              langs={langs}
              url={url}
              matchingSlug={matchingSlug}
              storageKey={storageKey}
            /> */}

            <LocalizedLink
              to="https://app.cyberbrief.fr/"
              style={{ textDecoration: "none" }}
            >
              <Buttons.HeaderBtn1
                title={nav_items.button_secondary}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </LocalizedLink>
          </Stack>
        </Box>
      </Popper>
    </>
  )
}

HeaderHome.propTypes = {
  langs: PropTypes.array.isRequired,
}

export default HeaderHome

// Mobile Subscribe btn

const MobileNavButton = ({ title }) => (
  <Button
    variant="contained"
    color="white"
    sx={{
      color: "#000065",
      padding: "0.5rem 0.7rem !important",
      fontWeight: "bold",
      fontSize: "10px",
      boxShadow: "none",
      borderRadius: "2px",
      textTransform: "uppercase",
      "&:hover": {
        boxShadow: "none",
      },
    }}
  >
    {title}
  </Button>
)

const BurgerIcon = () => {
  return (
    <svg
      width="30"
      height="17"
      viewBox="0 0 20 14"
      fill="none"
      style={{ marginTop: "10px" }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.995449C0 0.73144 0.105357 0.478244 0.292893 0.291561C0.48043 0.104878 0.734784 0 1 0H19C19.2652 0 19.5196 0.104878 19.7071 0.291561C19.8946 0.478244 20 0.73144 20 0.995449C20 1.25946 19.8946 1.51266 19.7071 1.69934C19.5196 1.88602 19.2652 1.9909 19 1.9909H1C0.734784 1.9909 0.48043 1.88602 0.292893 1.69934C0.105357 1.51266 0 1.25946 0 0.995449Z"
        fill="#ffffff"
      />
      <path
        d="M0 7.00033C0 6.73632 0.105357 6.48313 0.292893 6.29644C0.48043 6.10976 0.734784 6.00488 1 6.00488H19C19.2652 6.00488 19.5196 6.10976 19.7071 6.29644C19.8946 6.48313 20 6.73632 20 7.00033C20 7.26434 19.8946 7.51754 19.7071 7.70422C19.5196 7.89091 19.2652 7.99578 19 7.99578H1C0.734784 7.99578 0.48043 7.89091 0.292893 7.70422C0.105357 7.51754 0 7.26434 0 7.00033Z"
        fill="#ffffff"
      />
      <path
        d="M1 12.0098C0.734784 12.0098 0.48043 12.1146 0.292893 12.3013C0.105357 12.488 0 12.7412 0 13.0052C0 13.2692 0.105357 13.5224 0.292893 13.7091C0.48043 13.8958 0.734784 14.0007 1 14.0007H19C19.2652 14.0007 19.5196 13.8958 19.7071 13.7091C19.8946 13.5224 20 13.2692 20 13.0052C20 12.7412 19.8946 12.488 19.7071 12.3013C19.5196 12.1146 19.2652 12.0098 19 12.0098H1Z"
        fill="#ffffff"
      />
    </svg>
  )
}

export const LoginIcon = () => {
  return (
    <svg
      width="25"
      height="23"
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4756 3.18785C15.8372 2.50645 14.0354 2.32993 12.2978 2.68032C10.5602 3.03071 8.96374 3.89253 7.71077 5.15787C7.35351 5.51866 6.7714 5.52152 6.41061 5.16425C6.04982 4.80699 6.04696 4.22488 6.40422 3.86409C7.91313 2.34029 9.8375 1.3007 11.9344 0.877871C14.0313 0.45503 16.2054 0.668186 18.1817 1.49009C20.1579 2.31196 21.8467 3.70529 23.0353 5.49282C24.2239 7.28027 24.8593 9.38196 24.8618 11.5321C24.8644 13.6823 24.234 15.7855 23.0497 17.5758C21.8653 19.3662 20.1799 20.7636 18.2056 21.5902C16.2313 22.4169 14.0577 22.6353 11.9598 22.2175C9.86191 21.7998 7.93506 20.7648 6.42253 19.2447C6.0644 18.8847 6.06586 18.3026 6.4258 17.9445C6.78574 17.5864 7.36784 17.5878 7.72597 17.9478C8.98194 19.2101 10.5804 20.068 12.3189 20.4142C14.0573 20.7604 15.8587 20.5795 17.4955 19.8942C19.1323 19.2088 20.5318 18.0493 21.5162 16.5614C22.5005 15.0733 23.0252 13.3239 23.0231 11.5343C23.021 9.74478 22.4921 7.99662 21.5042 6.51095C20.5163 5.02535 19.1141 3.86927 17.4756 3.18785Z"
        fill="white"
      />
      <path
        d="M0.861816 12.0184V11.137H5.20901V8.22836L9.10021 11.607L5.20901 14.8682V12.0184H0.861816Z"
        fill="white"
      />
      <path
        d="M16.7674 8.39825C16.7674 9.90126 15.558 11.1197 14.0661 11.1197C12.5742 11.1197 11.3648 9.90126 11.3648 8.39825C11.3648 6.89524 12.5742 5.67681 14.0661 5.67681C15.558 5.67681 16.7674 6.89524 16.7674 8.39825Z"
        fill="white"
      />
      <path
        d="M13.9895 12.0314C10.5195 12.0156 9.10003 14.1599 9.10003 14.1599C9.10003 14.1599 10.0324 17.4135 13.9895 17.4135C17.9467 17.4135 18.8187 14.1599 18.8187 14.1599C18.8187 14.1599 17.2807 12.0465 13.9895 12.0314Z"
        fill="white"
      />
    </svg>
  )
}
