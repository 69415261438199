import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

// Intl
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"

// Components
import Header from "./Header"
import HeaderHome from "./Header-home"
import Footer from "./Footer"
import { LocalizedSeo } from "../components"

// Fonts
import "@fontsource/lato"
import "@fontsource/lato/300.css"
import "@fontsource/abril-fatface"

// Mui
import { ThemeProvider, Box } from "@mui/material"
import theme from "../theme"
import CssBaseline from "@mui/material/CssBaseline"
// import CookieBanner from "../../archive/CookieBanner"

// flattens the intl object so that nested strings are available => e.g. index.hero.title
const flattenMessages = (nestedMessages, prefix = "") => {
  if (nestedMessages === null) {
    return {}
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === "string") {
      Object.assign(messages, { [prefixedKey]: value })
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

const Layout = ({
  children,
  location,
  i18nMessages,
  title,
  description,
  banner,
  pathname,
  article,
  matchingSlug,
}) => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const url = location.pathname
        const { langs, defaultLangKey } = data.site.siteMetadata.languages
        const langKey = getCurrentLangKey(langs, defaultLangKey, url)
        const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, "/")
        const langsMenu = getLangs(
          langs,
          langKey,
          getUrlForLang(homeLink, url)
        ).map(item => ({
          ...item,
          link: item.link.replace(`/${defaultLangKey}/`, "/"),
        }))
        const {
          nav_fr,
          nav_en,
          footer_en,
          footer_fr /*cookie_en, cookie_fr*/,
        } = data
        const nav_items =
          langKey === "fr" ? nav_fr.frontmatter : nav_en.frontmatter

        const footer_items =
          langKey === "fr" ? footer_fr.frontmatter : footer_en.frontmatter

        // const cookie_items = langKey === "fr" ? cookie_fr : cookie_en

        return (
          <IntlProvider
            locale={langKey}
            messages={flattenMessages(i18nMessages)}
          >
            <CssBaseline />
            <ThemeProvider theme={theme}>
              <LocalizedSeo
                config={data.site}
                langKey={langKey}
                title={title}
                description={description}
                banner={banner}
                pathname={pathname}
                article={article}
              />
              <div>
                {location.pathname === "/" ? (
                  <HeaderHome
                    langs={langsMenu}
                    url={url}
                    nav_items={nav_items}
                    matchingSlug={matchingSlug}
                  />
                ) : (
                  <Header
                    langs={langsMenu}
                    url={url}
                    nav_items={nav_items}
                    matchingSlug={matchingSlug}
                  />
                )}
              </div>
              <Box
                component="main"
                m="0 auto"
                pt={
                  location.pathname === "/"
                    ? { base: "0px", md: "0px" }
                    : { base: "64px", md: "80px" }
                }
                maxWidth="1200px"
              >
                {children}
              </Box>
              <Footer footer_items={footer_items} />
              {/* <CookieBanner data={cookie_items} /> */}
            </ThemeProvider>
          </IntlProvider>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Layout

const query = graphql`
  fragment NavigationFragment on MarkdownRemarkFrontmatter {
    button_primary
    button_secondary
    links {
      title
      link
    }
  }
  # fragment CookieFragment on MarkdownRemarkFrontmatter {
  #   cookie_accept_button
  #   cookie_accept_selection_button
  #   cookie_reset_selection_button
  #   cookie_option_button
  #   cookie_decline_button
  #   cookie_modal {
  #     title
  #     read_more
  #     categories {
  #       title
  #       can_be_changed
  #       # description
  #       cookies {
  #         title
  #         cookie_name
  #         description
  #         link
  #       }
  #     }
  #   }
  # }
  fragment FooterFragment on MarkdownRemarkFrontmatter {
    title
    menu_columns {
      links {
        link
        title
      }
    }
    bottom_links {
      title
      link
    }
  }

  query LayoutQuery {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        logo
        siteUrl: url
        pathPrefix
        defaultBanner: banner
        twitter
        author
        en {
          titleAlt
          shortName
          defaultDescription: description
          defaultTitle: title
        }
        fr {
          titleAlt
          shortName
          defaultDescription: description
          defaultTitle: title
        }
        languages {
          defaultLangKey
          langs
        }
      }
    }
    nav_en: markdownRemark(
      fileAbsolutePath: { regex: "/content/layout/navbar.en.md/" }
    ) {
      frontmatter {
        ...NavigationFragment
      }
    }
    nav_fr: markdownRemark(
      fileAbsolutePath: { regex: "/content/layout/navbar.fr.md/" }
    ) {
      frontmatter {
        ...NavigationFragment
      }
    }
    footer_en: markdownRemark(
      fileAbsolutePath: { regex: "/content/layout/footer.en.md/" }
    ) {
      frontmatter {
        ...FooterFragment
      }
    }
    footer_fr: markdownRemark(
      fileAbsolutePath: { regex: "/content/layout/footer.fr.md/" }
    ) {
      frontmatter {
        ...FooterFragment
      }
    }
    # cookie_en: markdownRemark(
    #   fileAbsolutePath: { regex: "/content/layout/cookie-consent.en.md/" }
    # ) {
    #   frontmatter {
    #     ...CookieFragment
    #   }
    #   html
    # }
    # cookie_fr: markdownRemark(
    #   fileAbsolutePath: { regex: "/content/layout/cookie-consent.fr.md/" }
    # ) {
    #   frontmatter {
    #     ...CookieFragment
    #   }
    #   html
    # }
  }
`
