import React, { useState } from "react"

import PropTypes from "prop-types"

import {
  Stack,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material"
import LocalizedLink from "./LocalizedLink"
import slugify from "slugify"
import { FormattedMessage, FormattedDate } from "react-intl"
// import { GatsbyImage } from "gatsby-plugin-image"

const BlogPostGrid = ({ posts, showLimitedPosts, buttonText }) => {
  const theme = useTheme()

  const [limit, setLimit] = useState(12)

  const data = showLimitedPosts ? posts.slice(0, limit) : posts

  const bigScreens = useMediaQuery(theme.breakpoints.up("lg"))
    ? {
        sx: {
          "& > *:nth-of-type(3n+3)": { borderRight: "none" },
          "& > *": { borderRight: "1px solid #C4C4C4" },
        },
      }
    : null

  // const limitedPosts = showingAllPosts ? posts : posts.slice(0, 12)

  const mediumScreens = useMediaQuery(
    theme.breakpoints.up("md") && theme.breakpoints.between("md", "lg")
  )
    ? {
        sx: {
          "& > *:nth-of-type(2n+2)": { borderRight: "none" },
          "& > *": { borderRight: "1px solid #C4C4C4" },
        },
      }
    : null

  const handleShowNextPostsBatch = () => {
    setLimit(e => (e += 9))
  }

  return (
    <>
      {/* Post Grid */}
      <Stack
        direction="row"
        flexWrap="wrap"
        mx={{ md: "-1.8rem" }}
        overflow="hidden"
        maxWidth="100vw"
        {...mediumScreens}
        {...bigScreens}
      >
        {data.map(post => {
          return <SinglePost key={post.id} {...post} />
        })}
      </Stack>
      {/* Show More Banner  */}
      {showLimitedPosts && limit < posts.length && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          mt="-170px"
          mb={-8}
          position="relative"
          zIndex={2}
          height="20vh"
          sx={{
            background:
              "linear-gradient(179.32deg, rgba(255, 255, 255, 0.75) -1.02%, #FFFFFF 35.66%)",
          }}
        >
          <Button
            variant="text"
            onClick={handleShowNextPostsBatch}
            sx={{
              textTransform: "none",
            }}
          >
            <Typography
              sx={{
                pb: "0px",
                borderBottomColor: "primary",
                borderBottom: "1px solid",
              }}
            >
              {buttonText}
            </Typography>
          </Button>
        </Stack>
      )}
    </>
  )
}

BlogPostGrid.propTypes = {
  posts: PropTypes.array.isRequired,
  showLimitedPosts: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
}

BlogPostGrid.defaultProps = {
  showLimitedPosts: false,
  buttonText: "See more",
}

export default BlogPostGrid

const SinglePost = ({ title, published_at, image, content }) => {
  const slug = slugify(title)

  const isNotCurrentYear = new Date().getFullYear !==
    new Date(published_at).getFullYear && { year: "numeric" }

  const timeToRead = readingTime(content)

  return (
    <Box
      width="100%"
      mb={4}
      overflow="hidden"
      px={{ md: "2rem" }}
      maxWidth={{
        base: "100%",
        md: "50%",
        lg: "33%",
      }}
    >
      <LocalizedLink
        to={`/free-access/${slug}`}
        style={{ textDecoration: "none" }}
      >
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Stack
            direction="column"
            justifyContent="space-between"
            textAlign="left"
          >
            <Box>
              <Typography component="span" fontSize="0.75rem" color="#707070">
                <FormattedDate
                  value={published_at}
                  {...isNotCurrentYear}
                  month="long"
                  day="numeric"
                />{" "}
                <DotIcon /> <ReadingIcon />
                {timeToRead.readingTime} min
              </Typography>

              <Typography
                fontSize="0.875rem"
                color="#1B1A3C"
                fontWeight="bold"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {title}
              </Typography>
            </Box>
            <Typography
              component="span"
              color="#37459C"
              fontSize="0.75rem"
              sx={{ textDecoration: "underline" }}
            >
              <FormattedMessage id="read_more_link" />
            </Typography>
          </Stack>
          <Box width="130px" height="130px" flexShrink="0">
            {image && (
              <img
                src={image.url}
                alt={image.title}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            )}
            {/* <GatsbyImage
              image={localImage.childImageSharp.gatsbyImageData}
              alt={image.title}
            /> */}
          </Box>
        </Stack>
      </LocalizedLink>
    </Box>
  )
}

SinglePost.propTypes = {
  title: PropTypes.string.isRequired,
  published_at: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
}

export const DotIcon = () => (
  <Box display="inline" ml="4px" mr="6px">
    <svg
      width="4"
      height="4"
      viewBox="0 0 4 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginBottom: "3px" }}
    >
      <circle cx="2" cy="2" r="2" fill="#DBDBDB" />
    </svg>
  </Box>
)

export const ReadingIcon = () => (
  <Box display="inline" mr="4px">
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0C4.0111 0 3.0444 0.293245 2.22215 0.842652C1.3999 1.39206 0.759043 2.17295 0.380605 3.08658C0.00216642 4.00021 -0.0968502 5.00554 0.0960758 5.97545C0.289002 6.94535 0.765206 7.83627 1.46447 8.53553C2.16373 9.23479 3.05465 9.711 4.02455 9.90392C4.99445 10.0969 5.99979 9.99783 6.91342 9.6194C7.82705 9.24096 8.60794 8.60009 9.15735 7.77785C9.70675 6.9556 10 5.9889 10 5C10 4.34339 9.87067 3.69321 9.6194 3.08658C9.36812 2.47995 8.99983 1.92876 8.53553 1.46447C8.07124 1.00017 7.52005 0.631876 6.91342 0.380602C6.30679 0.129329 5.65661 0 5 0ZM5 9C4.20888 9 3.43552 8.7654 2.77772 8.32588C2.11992 7.88635 1.60723 7.26164 1.30448 6.53073C1.00173 5.79983 0.92252 4.99556 1.07686 4.21964C1.2312 3.44371 1.61216 2.73098 2.17157 2.17157C2.73098 1.61216 3.44372 1.2312 4.21964 1.07686C4.99556 0.922518 5.79983 1.00173 6.53074 1.30448C7.26164 1.60723 7.88635 2.11992 8.32588 2.77772C8.7654 3.43551 9 4.20887 9 5C9 6.06086 8.57857 7.07828 7.82843 7.82842C7.07828 8.57857 6.06087 9 5 9Z"
        fill="#707070"
      />
      <path
        d="M7 4.5H5.5V3C5.5 2.86739 5.44732 2.74021 5.35355 2.64645C5.25978 2.55268 5.13261 2.5 5 2.5C4.86739 2.5 4.74021 2.55268 4.64645 2.64645C4.55268 2.74021 4.5 2.86739 4.5 3V5C4.5 5.13261 4.55268 5.25978 4.64645 5.35355C4.74021 5.44732 4.86739 5.5 5 5.5H7C7.13261 5.5 7.25979 5.44732 7.35355 5.35355C7.44732 5.25978 7.5 5.13261 7.5 5C7.5 4.86739 7.44732 4.74021 7.35355 4.64645C7.25979 4.55268 7.13261 4.5 7 4.5Z"
        fill="#707070"
      />
    </svg>
  </Box>
)

export function readingTime(post) {
  const WORDS_PER_MINUTE = 200
  let result = {}
  const regex = /\w+/g
  result.wordCount = (post || "").match(regex).length

  result.readingTime = Math.ceil(result.wordCount / WORDS_PER_MINUTE)
  return result
}
