import React from "react"
import PropTypes from "prop-types"
import { Box, Typography, Stack } from "@mui/material"
import { FaTwitter, FaLinkedinIn } from "react-icons/fa"
import { GatsbyImage } from "gatsby-plugin-image"
import IconContainer from "./IconContainer"

const RowLayout = ({ categories }) => {
  if (!categories) return null

  return categories.map((category, index) => {
    return (
      <Box key={category.title + index}>
        <Box py={2} mb={2} mt={{ md: index !== 0 && 4 }}>
          <Typography
            component="h2"
            fontWeight="bold"
            color="#1B1A3C"
            fontSize="1.75rem"
            pb="6px"
            mb="12px"
            borderBottom="1px solid #C4C4C4"
          >
            {category.title}
          </Typography>
          <Typography>{category.subtitle}</Typography>
        </Box>
        <Box>
          {category.people.map((item, index) => {
            return (
              <Stack key={item.name + index} direction="row" mb={2} spacing={2}>
                <Box>
                  <Box width="70px" height="70px" borderRadius="50%">
                    <GatsbyImage
                      image={item.image.childImageSharp.gatsbyImageData}
                      alt={item.name}
                      style={{ borderRadius: "50%" }}
                    />
                  </Box>
                  <Stack
                    direction="row"
                    spacing={1}
                    py={1}
                    justifyContent="center"
                  >
                    {item.twitter && (
                      <IconContainer href={item.twitter}>
                        <FaTwitter size="17px" color="#fff" />
                      </IconContainer>
                    )}
                    {item.linked_in && (
                      <IconContainer href={item.linked_in}>
                        <FaLinkedinIn size="17px" color="#fff" />
                      </IconContainer>
                    )}
                  </Stack>
                </Box>
                <Box flex="1">
                  <Typography
                    color="primary"
                    fontWeight="bold"
                    fontSize="1.25rem"
                  >
                    {item.name}
                  </Typography>
                  <Typography fontSize="1.125rem" color="#464646">
                    {item.job_title}
                  </Typography>
                  <Typography color="#464646">{item.description}</Typography>
                </Box>
              </Stack>
            )
          })}
        </Box>
      </Box>
    )
  })
}

RowLayout.propTypes = {
  categories: PropTypes.array.isRequired,
}

export default RowLayout
