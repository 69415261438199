import React from "react"
import PropTypes from "prop-types"
import { Button, Stack } from "@mui/material"

const CategoryButtons = ({
  categories,
  setSelectedCategory,
  selectedCategory,
  allowUnselectCategory = true,
}) => {
  const sharedButtonStyle = {
    mr: { base: 2, md: 1 },
    ml: { base: 0, md: 1 },
    mb: 2,
    p: { base: "0.5rem 0.75rem", md: "0.875rem 1.375rem!important" },
    borderRadius: "6px!important",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  }

  const notActiveButtonStyle = {
    backgroundColor: "#F7F7FB!important",
    color: "#000065",
    ...sharedButtonStyle,
  }

  return (
    <Stack
      direction="row"
      py={2}
      justifyContent={{ base: "flex-start", md: "center" }}
      flexWrap="wrap"
    >
      {categories.map(({ title }) => {
        const isCurrentCategory = title === selectedCategory

        const handleCategorySelect = () =>
          isCurrentCategory && allowUnselectCategory
            ? setSelectedCategory("")
            : setSelectedCategory(title)

        return (
          <Button
            key={title}
            onClick={handleCategorySelect}
            variant="contained"
            sx={isCurrentCategory ? sharedButtonStyle : notActiveButtonStyle}
          >
            {title}
          </Button>
        )
      })}{" "}
    </Stack>
  )
}

CategoryButtons.propTypes = {
  categories: PropTypes.array.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
}

export default CategoryButtons
