import React from "react"
import PropTypes from "prop-types"
import { Box, Stack, Typography } from "@mui/material"
import { Buttons } from "."
import LocalizedLink from "./LocalizedLink"

const Banner = ({ data }) => {
  const { title, subtitle, button } = data
  return (
    <Box
      component="section"
      width="100vw"
      position="relative"
      minHeight="20vh"
      left="50%"
      right="50%"
      ml="-50vw"
      mr="-50vw"
      sx={{
        backgroundColor: "#FBFBFF",
      }}
      py={{ base: 4, md: 8 }}
    >
      <Stack
        maxWidth="1200px"
        width="100%"
        py={2}
        px={2}
        mx="auto"
        display={{ base: "block", md: "flex" }}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Box mb={{ base: 3, md: 4 }}>
          <Typography
            component="h4"
            display={{ base: "inline", md: "block" }}
            fontSize={{ base: "1.375rem", md: "1.875rem" }}
            lineHeight={{ base: "1.625rem", md: "2.25rem" }}
            fontWeight="bold"
            color="primary"
            mb={2}
          >
            {title}
          </Typography>{" "}
          <Typography
            fontSize="1.375rem"
            display={{ base: "inline", md: "block" }}
            lineHeight="1.625rem"
            color="primary"
            maxWidth="850px"
          >
            {subtitle}
          </Typography>
        </Box>
        <LocalizedLink to={button.link} style={{ textDecoration: "none" }}>
          <Buttons.Primary title={button.title} />
        </LocalizedLink>
      </Stack>
    </Box>
  )
}

export default Banner

Banner.propTypes = {
  data: PropTypes.object.isRequired,
}
