import React from "react"
import PropTypes from "prop-types"
import { Box, Typography, Stack } from "@mui/material"
import { FaTwitter, FaLinkedinIn } from "react-icons/fa"
import { GatsbyImage } from "gatsby-plugin-image"
import IconContainer from "./IconContainer"

const GridLayout = ({ categories }) => {
  if (!categories) return null

  return categories.map((category, index) => {
    return (
      <Box key={category.title + index}>
        <Box py={{ md: 2 }} mt={{ base: 1, md: index !== 0 && 2 }}>
          <Typography
            component="h2"
            fontWeight="bold"
            color={{ base: "#464646", md: "#1B1A3C" }}
            fontSize={{ base: "1.125rem", md: "1.75rem" }}
            pb="6px"
            mb="12px"
            borderBottom="1px solid #C4C4C4"
          >
            {category.title}
          </Typography>
          <Typography>{category.subtitle}</Typography>
        </Box>
        <Stack direction="row" flexWrap="wrap" mx={{ md: "-1.3rem" }}>
          {category.people.map((item, index) => {
            return (
              <Stack
                key={item.name + index}
                mb={{ md: 2 }}
                spacing={1}
                width="100%"
                m={{ base: "1.5rem 0", md: "1rem 1.5rem" }}
                maxWidth={{
                  base: "calc(100%)",
                  md: "calc(50% - 3rem)",
                  lg: "calc(33% - 3rem)",
                }}
              >
                <Box>
                  <Box width="85px" height="85px" borderRadius="50%">
                    <GatsbyImage
                      image={item.image.childImageSharp.gatsbyImageData}
                      alt={item.name}
                      style={{ borderRadius: "50%" }}
                    />
                  </Box>
                </Box>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Box>
                    <Typography
                      color="primary"
                      fontWeight="bold"
                      fontSize="1.125rem"
                    >
                      {item.name}
                    </Typography>
                    <Typography fontSize="0.875rem" color="#A4A4B1">
                      {item.job_title}
                    </Typography>
                  </Box>

                  <Stack direction="row" spacing={1} py={1}>
                    {item.twitter && (
                      <IconContainer href={item.twitter}>
                        <FaTwitter size="17px" color="#fff" />
                      </IconContainer>
                    )}
                    {item.linked_in && (
                      <IconContainer href={item.linked_in}>
                        <FaLinkedinIn size="17px" color="#fff" />
                      </IconContainer>
                    )}
                  </Stack>
                </Stack>
                <Box flex="1">
                  <Typography color="#1B1A3C" fontSize="0.875rem">
                    {item.description}
                  </Typography>
                </Box>
              </Stack>
            )
          })}
        </Stack>
      </Box>
    )
  })
}

GridLayout.propTypes = {
  categories: PropTypes.array.isRequired,
}

export default GridLayout
