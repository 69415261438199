import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"

const IconContainer = ({ children, href }) => (
  <Box
    bgcolor="#000065"
    component="a"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    borderRadius="50%"
    width="30px"
    height="30px"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    {children}
  </Box>
)

IconContainer.propTypes = {
  href: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default IconContainer
