const markdownStyles = {
  overflowWrap: "break-word",
  a: {
    color: "primary.main",
  },
  blockquote: {
    borderLeft: "1px solid rgba(0,0,0,0.1)",
    padding: "0 15px",
    fontStyle: "italic",
  },
}

export default markdownStyles
