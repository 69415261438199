import React, { useState } from "react"
import { useForm } from "react-hook-form"
import PropTypes from "prop-types"

import {
  Box,
  Typography,
  Button,
  Stack,
  FormControl,
  Radio,
} from "@mui/material"

import { VscError } from "react-icons/vsc"
import { AiOutlineFileDone } from "react-icons/ai"

import { fetchPostJSON } from "../../../utils/api-helper"
import getStripe from "../../../utils/get-stripe"

const SubscribeForm = ({ data }) => {
  const {
    default_input_name,
    default_input_email,
    enterprise_company_name,
    enterprise_employee_number,
    enterprise_telephone_number,
    enterprise_message,
    default_submit_button,
    enterprise_submit_button,
    price_items,
    error_message,
    enterprise_success_message,
  } = data

  const [currentTier, setCurrentTier] = React.useState(price_items[0])

  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState(false)
  const [enterpriseSuccess, setEnterpriseSuccess] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const redirectToCheckout = async ({ email, plan, name }) => {
    setLoading(true)
    const checkoutEndpoint = `${process.env.GATSBY_API_HOST}${process.env.GATSBY_API_CHECKOUT_ENDPOINT}`

    // Create a Checkout Session.
    await fetchPostJSON(checkoutEndpoint, {
      email,
      plan,
      coupon: true,
      name,
    })
      .then(async session => {
        const stripe = await getStripe()
        return stripe.redirectToCheckout({ sessionId: session.id })
      })
      .then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using `error.message`.
        if (result.error) {
          alert(result.error.message)
        }
      })
  }

  // NOTE: THIS IS A TEMPORARY FUNCTION UNTIL ENTERPRISE ENDPOINT RETURNS PROPER STATUSCODE
  const submitPostToEnterpriseApi = async ({ endpoint, data }) => {
    const checkoutEndpoint = `${process.env.GATSBY_API_HOST}${endpoint}`

    try {
      const response = await fetchPostJSON(checkoutEndpoint, {
        ...data,
      })

      if (!response.id) {
        setApiError(true)
        return
      }
    } catch (error) {
      setApiError(true)
    }
    setLoading(false)
    setEnterpriseSuccess(true)
  }

  const onSubmit = data => {
    switch (currentTier.api_type) {
      case "checkout":
        redirectToCheckout({
          email: data.email.trim(),
          plan: currentTier.plan,
          name: data.name.trim(),
        })
        break
      case "enterprise":
        submitPostToEnterpriseApi({
          endpoint: process.env.GATSBY_API_ENTERPRISE_ENDPOINT,
          data: {
            contactName: data.name.trim(),
            email: data.email.trim(),
            company: data.company_name.trim(),
            consideredSubscribers: data.company_employee_number,
            phone: data.company_telephone_number,
            message: data.company_message.trim(),
          },
        })
        break
      default:
        break
    }
  }

  const handleChange = event => {
    setCurrentTier(price_items.find(i => i.title === event.target.value))
  }

  return (
    <FormControl
      component="form"
      sx={{ width: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="stretch"
        justifyContent="center"
      >
        {price_items.map((item, i) => (
          <Stack
            key={`price_item_${i}`}
            direction="column"
            boxShadow="0px 0px 10px rgba(0, 0, 101, 0.1)"
            borderRadius="10px"
            px={1}
            py={2}
            spacing={2}
            minWidth={{ base: "100%", lg: "20%" }}
            flex={{ sm: 1 }}
            maxWidth="100%"
            mb={{ base: 2, md: 1 }}
            mx={{ base: 0, lg: 1, xl: 1 }}
            textAlign="center"
            alignItems="center"
            justifyContent="space-between"
            backgroundColor={
              currentTier === price_items[i]
                ? "#FBFBFF"
                : "rgba(255, 255, 255, 1)"
            }
            border={
              currentTier === price_items[i]
                ? "1px solid rgba(0, 0, 101, 1)"
                : "1px solid rgba(0, 0, 0, 0.1)"
            }
          >
            <Typography
              sx={{
                color: "grey",
                component: "h5",
                fontSize: "1rem",
                textTransform: "uppercase",
                fontWeight: "bold",
                lineHeight: "1.1875rem",
                fontFamily: "Lato",
              }}
            >
              {item.title}
            </Typography>
            <Box>
              <Typography
                fontSize="1.5rem"
                fontWeight="bold"
                lineHeight="1.1875rem"
                color="primary"
                pb={1}
              >
                {item.price_title}
              </Typography>
              <Typography
                fontSize="0.875rem"
                lineHeight="1.1875rem"
                color="grey"
              >
                {item.info}
              </Typography>
            </Box>
            <Box width="30%" height="1px" bgcolor="rgba(196, 196, 196, 0.7)" />
            <Typography fontSize="0.875rem" lineHeight="1.1875rem" color="grey">
              {item.option}
            </Typography>
            <Radio
              checked={currentTier === price_items[i]}
              onChange={handleChange}
              value={item.title}
              name="radio-buttons"
              inputProps={{ "aria-label": "subscribe" }}
            />
          </Stack>
        ))}
      </Stack>
      <Box
        width="100%"
        height="1px"
        bgcolor="rgba(196, 196, 196, 0.3)"
        my={4}
      />

      {/* /////////// FORM //////////// */}

      {/* ALL INPUTS if no error message from API*/}
      {apiError ? (
        <Stack alignItems="center" spacing={2}>
          <VscError size={40} color="#DA3433" />
          <Typography textAlign="center" color="secondary">
            {error_message}
          </Typography>
        </Stack>
      ) : enterpriseSuccess ? (
        <Stack alignItems="center" spacing={2}>
          <AiOutlineFileDone size={40} color="#16b567" />
          <Typography textAlign="center" color="#1db86a">
            {enterprise_success_message}
          </Typography>
        </Stack>
      ) : (
        <>
          <Box width="100%" maxWidth="560px" mx="auto" text-align="center">
            {/* Display name input */}
            <CustomInput
              title={default_input_name}
              required
              errorMessage="Please enter your name."
              errorRequirement={
                errors.name?.type === "required" ||
                errors.name?.type === "pattern"
              }
              formName="name"
              formRegister={register}
              pattern={
                 /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
              }
            />

            {/* Display Email Input */}
            <CustomInput
              title={default_input_email}
              required
              errorMessage="Please enter a valid email."
              errorRequirement={
                errors.email?.type === "required" ||
                errors.email?.type === "pattern"
              }
              formName="email"
              formRegister={register}
              pattern={
                /* eslint-disable no-console, no-control-regex*/
                /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
              }
            />

            {/* Diplay unique inputs for enterprise tier */}
            {currentTier.enterprise && (
              <>
                {/* Company Name */}
                <CustomInput
                  title={enterprise_company_name}
                  required
                  errorMessage="Please enter a valid company name."
                  errorRequirement={
                    errors.company_name?.type === "required" ||
                    errors.company_name?.type === "pattern"
                  }
                  formName="company_name"
                  formRegister={register}
                  pattern={
                    /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
                  }
                />

                {/* Company Employee Number */}
                <CustomInput
                  title={enterprise_employee_number}
                  required
                  inputType="number"
                  errorMessage="Please enter a valid number."
                  errorRequirement={
                    errors.company_employee_number?.type === "required"
                  }
                  formName="company_employee_number"
                  formRegister={register}
                />

                {/* Company Telephone Number */}
                <CustomInput
                  title={enterprise_telephone_number}
                  errorMessage="Please enter a valid telephone number."
                  errorRequirement={
                    errors.company_telephone_number?.type === "pattern"
                  }
                  formName="company_telephone_number"
                  formRegister={register}
                  pattern={/^[0-9+ ,.'-/]+$/u}
                />

                {/* Company Telephone Number */}
                <CustomInput
                  title={enterprise_message}
                  formName="company_message"
                  inputComponent="textarea"
                  formRegister={register}
                />
              </>
            )}

            {/* Submit Button */}
            <Button
              disabled={loading}
              variant="contained"
              type="submit"
              color={currentTier.enterprise ? "primary" : "secondary"}
              sx={{
                width: "100%",
                color: "white!important",
                mt: 1,
                padding: "0.7rem 1.5rem !important",
                fontWeight: "bold",
                boxShadow: "none",
                textTransform: "uppercase",
                "&:hover": {
                  boxShadow: "none",
                  bgcolor: !currentTier.enterprise && "secondary.hover",
                },
              }}
            >
              {/* Display different button title for enterprise */}
              {currentTier.enterprise
                ? enterprise_submit_button
                : default_submit_button}
            </Button>
          </Box>
        </>
      )}
    </FormControl>
  )
}

const inputStyle = {
  border: "0.3px solid rgba(0, 0, 101, 0.3)",
  backgroundColor: "#F7F7FB",
  borderRadius: "2px",
  padding: "1rem",
  maxWidth: "100%",
  minWidth: "100%",
  marginBottom: "1rem",
}

SubscribeForm.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SubscribeForm

const CustomInput = ({
  title,
  required = false,
  errorMessage,
  errorRequirement,
  inputComponent,
  inputType,
  formName,
  pattern,
  formRegister,
}) => (
  <>
    <Typography fontWeight="bold" color="#464646" mb="0.3rem">
      {title}
      {required && (
        <Typography component="span" fontWeight="bold" color="secondary">
          *
        </Typography>
      )}
      <Typography component="span" pl={1} fontSize="0.8rem" color="secondary">
        {errorRequirement && errorMessage}
      </Typography>
    </Typography>
    <Box
      component={inputComponent}
      type={inputType}
      sx={inputStyle}
      {...formRegister(formName, {
        pattern,
        required: required,
      })}
    />
  </>
)

CustomInput.propTypes = {
  title: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  errorRequirement: PropTypes.bool,
  inputComponent: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  pattern: PropTypes.instanceOf(RegExp),
  formRegister: PropTypes.func.isRequired,
}

CustomInput.defaultProps = {
  inputComponent: "input",
  inputType: "text",
  required: false,
}
