import React from "react"
import PropTypes from "prop-types"
import { Stack, Typography, Box } from "@mui/material"
import { GatsbyImage } from "gatsby-plugin-image"
import Buttons from "../Buttons"
import LocalizedLink from "../LocalizedLink"
import Image from "../../../static/assets/bg.jpg"

const styles = {
  sectionContainer: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
}

const Hero = ({ data }) => {
  const { title, subtitle, button_title, image } = data
  return (
    <Stack
      style={styles.sectionContainer}
      component="section"
      bgcolor="#FBFBFF"
      width="100vw"
      position="relative"
      left="50%"
      right="50%"
      ml="-50vw"
      mr="-50vw"
      py={{ base: 12, md: 18 }}
    >
      <Stack
        maxWidth="1200px"
        width="100%"
        px={2}
        py={{ base: "6", md: "12" }}
        direction={{ base: "column", md: "row" }}
        mx="auto"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Stack
          maxWidth="600px"
          direction="column"
          alignItems="flex-start"
          mb={2}
        >
          <Typography component="h1" variant="h4" color="#fff" mb={2}>
            {title}
            <Typography component="span" color="secondary" variant="h4">
              .
            </Typography>
          </Typography>
          <Typography mb={5} color="#fff" fontSize="20px">
            {subtitle}
          </Typography>
          <LocalizedLink to="/subscribe" style={{ textDecoration: "none" }}>
            <Buttons.HeaderBtn2 title={button_title} />
          </LocalizedLink>
        </Stack>
        <Box>
          <GatsbyImage
            style={{ maxHeight: "350px" }}
            image={image.childImageSharp.gatsbyImageData}
            alt="Hero Image Cyberbrief"
            objectFit="contain"
          />
        </Box>
      </Stack>
    </Stack>
  )
}

Hero.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Hero
