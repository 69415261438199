import React from "react"
import PropTypes from "prop-types"
import {
  Box,
  Stack,
  Typography,
  ListItem,
  ListItemText,
  List,
} from "@mui/material"
import { Buttons } from ".."
import LocalizedLink from "../LocalizedLink"
import { GatsbyImage } from "gatsby-plugin-image"

const Subscribe = ({ data }) => {
  const {
    title,
    subtitle,
    image,
    price,
    price_info,
    button_title,
    button_link,
    button_plans_title,
    button_plans_link,
    features,
  } = data
  return (
    <Box
      id="trial"
      component="section"
      width="100vw"
      position="relative"
      minHeight="20vh"
      left="50%"
      right="50%"
      ml="-50vw"
      mr="-50vw"
      py={{ base: 6, md: 8 }}
    >
      <Typography
        color="primary"
        component="h2"
        variant="h2"
        textAlign="center"
        mb={2}
        px={2}
      >
        {title}
        <Typography component="span" variant="h2" color="secondary">
          .
        </Typography>
      </Typography>
      <Typography textAlign="center">{subtitle}</Typography>
      <Stack
        maxWidth="1200px"
        width="100%"
        pb={4}
        pt={8}
        px={2}
        direction={{ base: "column", lg: "row" }}
        mx="auto"
        justifyContent="center"
        alignItems={{ base: "center" }}
        spacing={{ base: 6, lg: 6 }}
      >
        {/* left section - Illustration */}
        <Box width="400px">
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt="illustration"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
            }}
          />
        </Box>
        {/* right section - Content */}
        <Stack
          direction="column"
          spacing={3}
          width={{ base: "100%", lg: "40%" }}
          textAlign="center"
        >
          <Box>
            <Typography
              fontWeight="900"
              fontSize={{ base: "1.75rem", lg: "2rem" }}
              lineHeight="1.5rem"
              color="primary"
              mb={2}
            >
              {price}
            </Typography>
            <Typography
              fontSize="1.125rem"
              lineHeight="1.375rem"
              fontWeight="500"
            >
              {price_info}
            </Typography>
          </Box>
          <Stack direction="column" spacing={2}>
            <LocalizedLink to={button_link} style={{ textDecoration: "none" }}>
              <Buttons.Secondary title={button_title} />
            </LocalizedLink>
            <LocalizedLink to={button_plans_link}>
              <Typography
                component="span"
                color="#37459C"
                sx={{ textDecoration: "underline" }}
              >
                {button_plans_title}
              </Typography>
            </LocalizedLink>
          </Stack>
          {/* divider */}
          <Box width="100%" height="1px" bgcolor="rgba(196, 196, 196, 0.7)" />
          <List style={{ alignSelf: "flex-start" }} disablePadding="true">
            {features.map((i, index) => (
              <FeatureItem key={`feature_item_${index}`} title={i.title} />
            ))}
          </List>
        </Stack>
      </Stack>
    </Box>
  )
}

Subscribe.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Subscribe

export const FeatureItem = ({ title }) => {
  return (
    <ListItem alignItems="flex-start" disablePadding="true">
      <Box mr={2} mt={1}>
        <CheckIcon />
      </Box>
      <ListItemText>{title}</ListItemText>
    </ListItem>
  )
}

export const CheckIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.6109" cy="10.8457" r="10" fill="#FBFBFF" />
    <path
      d="M7.15436 10.8465L9.74695 13.4391L14.0679 8.25391"
      stroke="#000065"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
