import React from "react"
import PropTypes from "prop-types"
import {
  Box,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from "@mui/material"
import { IoIosArrowDown } from "react-icons/io"

export default function QuestionAccordion({ questions }) {
  return (
    <Box py={4}>
      {questions.map((item, i) => (
        <Question {...item} key={`question-${i}`} index={i} />
      ))}
    </Box>
  )
}

QuestionAccordion.propTypes = {
  questions: PropTypes.array.isRequired,
}

const Question = ({ title, text, index }) => {
  return (
    <Accordion
      sx={{
        boxShadow: "0px 0px 15px rgba(0, 0, 101, 0.08)",
        borderRadius: "10px!important",
        mb: "2rem!important",
        "&::before, *": {
          bgcolor: "transparent",
        },
        p: "5px",
      }}
    >
      <AccordionSummary
        expandIcon={<IoIosArrowDown size={22} color="#000065" />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography fontSize="1.25rem" fontWeight="bold" color="primary">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography fontSize="1.125rem" color="#1B1A3C" lineHeight="1.875rem">
          {text}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}
