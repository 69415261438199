import { createTheme } from "@mui/material/styles"

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#000065",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#DA3433",
      hover: "#EA3323",
      contrastText: "#FFFFFF",
    },
    red: {
      main: "#DA3433",
    },
    black: {
      main: "#242424",
    },
    grey: {
      main: "#464646",
    },
    off_white: {
      main: "#FBFBFF",
    },
    white: {
      main: "#FFFFFF",
      contrastText: "#000065",
    },
  },
  breakpoints: {
    values: {
      base: 0,
      sm: 374,
      md: 767,
      lg: 1023,
      xl: 1200,
    },
  },
  typography: {
    fontFamily: "Lato",
    h1: {
      fontFamily: "Abril Fatface",
    },
    h2: {
      fontFamily: "Abril Fatface",
      fontSize: "2rem",
    },
    h3: {
      fontFamily: "Abril Fatface",
    },
    h4: {
      fontFamily: "Abril Fatface",
    },
    h5: {
      fontFamily: "Abril Fatface",
    },
    h6: {
      fontFamily: "Abril Fatface",
    },
    span: {
      fontFamily: "Lato",
    },
  },
})

export default theme
