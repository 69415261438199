import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"

const LocalizedLink = ({ children, to, ...props }) => {
  const { locale } = useIntl()

  const link = locale === "en" ? `/en${to}` : to

  // Check if url is from current site or different origin
  const regExp = new RegExp(
    "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})"
  )

  // If different origin,z use standard href and not Gatsby Link
  if (regExp.test(to)) {
    return (
      <a href={to} {...props}>
        {children}
      </a>
    )
  }

  // Otherwise use GatsbyLink by default
  return (
    <Link to={link} {...props}>
      {children}
    </Link>
  )
}

export default LocalizedLink

LocalizedLink.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
