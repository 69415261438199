import React from "react"
import PropTypes from "prop-types"
import { Stack, Box, Typography } from "@mui/material"

import SlickSlider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"

const Reviews = ({ data }) => {
  const { title, items } = data
  return (
    <Stack
      component="section"
      bgcolor="#FBFBFF"
      width="100vw"
      position="relative"
      left="50%"
      right="50%"
      ml="-50vw"
      mr="-50vw"
    >
      <Box maxWidth="1200px" width="100%" mx="auto" px={2}  py={{ base: 6, md: 8 }}>
        <Box
          maxWidth="780px"
          mx="auto"
          textAlign="center"
          mb={{ base: 4, md: 8 }}
        >
          <Typography component="h1" variant="h4" color="#000065" mb={2}>
            <Typography component="span" color="secondary" variant="h4">
              {title.red}
              <br />
            </Typography>
            {title.blue}
            <Typography component="span" color="secondary" variant="h4">
              !
            </Typography>
          </Typography>
        </Box>
        <Slider slides={items} />
      </Box>
    </Stack>
  )
}

Reviews.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Reviews

const Slider = ({ slides }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          centerMode: true,
        },
      },

      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  }

  return (
    <Box
      maxWidth="100vw"
      overflow="hidden"
      sx={{
        ".slick-track": {
          display: "flex!important",
          pb: 2,
        },
        ".slick-slide": {
          height: "auto!important",
        },
        ".slick-slide > *": {
          height: "100%!important",
        },
      }}
    >
      <SlickSlider {...settings}>
        {slides.map((slide, i) => (
          <ReviewCard key={`review_card_${i}`} {...slide} />
        ))}
      </SlickSlider>
    </Box>
  )
}

Slider.propTypes = {
  slides: PropTypes.array.isRequired,
}

const ReviewCard = ({ text, name, job_title, image }) => (
  <Stack
    p="1.3125rem 1.3125rem 1.7rem"
    mx={{ base: 1, md: 2 }}
    my={1}
    boxShadow="0px 0px 10px rgba(0, 0, 101, 0.1)"
    bgcolor="#fff"
    borderRadius="14px"
    height="100%"
    justifyContent="space-between"
  >
    <Stack flexGrow={1} justifyContent="center" mb={1}>
      <Typography
        color="#464646!important"
        lineHeight="1.2rem"
        // fontStyle="italic"
        mb={1}
      >
        "{text}"
      </Typography>
    </Stack>
    <Stack direction="row" alignItems="center" spacing={2} flexGrow={0}>
      <Box
        flexShrink="0"
        width="45px"
        height="45px"
        bgcolor="#33333380"
        borderRadius="50%"
        border="2px solid #DB3433"
      >
        <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={name} />
      </Box>
      <Box fontSize="0.875rem" color="black">
        <Typography fontWeight="bold" lineHeight="1.0625rem" mb="0.2rem">
          {name}
        </Typography>
        <Typography lineHeight="1.0625rem">{job_title}</Typography>
      </Box>
    </Stack>
  </Stack>
)

ReviewCard.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  job_title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}
