import React from "react"
import PropTypes from "prop-types"
import { Stack, Box, Typography, Chip } from "@mui/material"
import { GatsbyImage } from "gatsby-plugin-image"

const Features = ({ data }) => {
  const { title, subtitle, items } = data
  return (
    <Box component="section" py={{ base: 6, md: 8 }} px={2} mb={2}>
      <Box mb={4} textAlign="center" maxWidth="780px" mx="auto">
        <Typography component="h2" variant="h2" color="primary" mb={2}>
          {title.blue}{" "}
          <Typography component="span" variant="h2" color="secondary">
            {title.red}
          </Typography>
        </Typography>
        <Typography>{subtitle}</Typography>
      </Box>
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="stretch"
        justifyContent="center"
        mr={-2}
      >
        {items.map((item, index) => (
          <Feature
            key={item.icon.id + index}
            title={item.title}
            text={item.subtitle}
            icon={item.icon}
            index={index}
          />
        ))}
      </Stack>
    </Box>
  )
}

Features.propTypes = {
  data: PropTypes.object.isRequired,
}

const Feature = ({ icon, title, text, index }) => {
  return (
    <Stack
      direction="column"
      boxShadow="0px 0px 10px rgba(0, 0, 101, 0.1)"
      borderRadius="10px"
      px={2}
      py={3}
      minWidth={{ base: "100%", sm: "46%", lg: "20%" }}
      flex={{ sm: 1 }}
      maxWidth="100%"
      mb={{ base: 2, md: 3 }}
      mr={{ base: 2, md: 3 }}
    >
      {index > 0 ? (
        <Stack direction="row" justifyContent="space-between">
          <Box alignSelf="left" mb={1}>
            <GatsbyImage
              image={icon.childImageSharp.gatsbyImageData}
              alt={title}
              style={{ maxWidth: "40px", height: "50px" }}
              objectFit="contain"
            />
          </Box>
          {index !== 2 ? (
            <Chip label="Bientôt" color="primary" size="small" />
          ) : (
            <Chip label="Bêta" color="primary" size="small" />
          )}
        </Stack>
      ) : (
        <Box alignSelf="left" mb={1}>
          <GatsbyImage
            image={icon.childImageSharp.gatsbyImageData}
            alt={title}
            style={{ maxWidth: "40px", height: "50px" }}
            objectFit="contain"
          />
        </Box>
      )}
      <Typography
        component="h5"
        fontSize="1rem"
        fontWeight="bold"
        lineHeight="1.1875rem"
        color="primary"
        fontFamily="Lato"
        mb={1}
      >
        {title}
      </Typography>
      <Typography fontSize="0.875rem" lineHeight="1.1875rem" color="grey">
        {text}
      </Typography>
    </Stack>
  )
}

export default Features
