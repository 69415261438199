import React from "react"
import { Button } from "@mui/material"

const CustomButton = ({
  title,
  variant = "contained",
  color = "primary",
  hoverColor,
  style = {},
  isLink = false,
  ...props
}) => {
  if (!title) return null

  return (
    <Button
      variant={variant}
      // LinkComponent={isLink && "span"}
      sx={{
        // color: variant === "contained" && "white!important",
        // color: "black",
        padding: "0.7rem 1.5rem !important",
        fontWeight: variant === "contained" && "bold",
        border: variant === `outlined" && "1px solid ${color} !important`,
        boxShadow: "none",
        textTransform: "uppercase",
        "&:hover": {
          boxShadow: "none",
          bgcolor: hoverColor,
        },
        ...style,
      }}
      color={color}
      {...props}
    >
      {title}
    </Button>
  )
}

const Primary = ({ title, variant = "contained", style, ...props }) => (
  <CustomButton
    title={title}
    variant={variant}
    color="primary"
    style={style}
    {...props}
  />
)

const Secondary = ({ title, variant = "contained", style, ...props }) => (
  <CustomButton
    title={title}
    variant={variant}
    color="secondary"
    hoverColor="secondary.hover"
    style={style}
    {...props}
  />
)

const HeaderBtn1 = ({ title, variant = "contained", style, ...props }) => (
  <CustomButton
    title={title}
    variant={variant}
    color="white"
    style={style}
    {...props}
  />
)

const HeaderBtn2 = ({ title, variant = "contained", style, ...props }) => (
  <CustomButton
    title={title}
    variant={variant}
    color="white"
    style={style}
    {...props}
  />
)

const Buttons = { Primary, Secondary, HeaderBtn1, HeaderBtn2 }
export default Buttons
