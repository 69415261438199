import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"

import SlickSlider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const LogoSlider = ({ data }) => {
  const { title, logos } = data
  return (
    <Box
      component="section"
      position="relative"
      pt={6}
      pb={8}
      bgcolor="#FBFBFF"
      width="100vw"
      left="50%"
      right="50%"
      ml="-50vw"
      mr="-50vw"
    >
      <Typography
        component="h4"
        variant="h4"
        color="primary"
        textAlign="center"
        mb={5}
      >
        {title}
        <Typography component="span" variant="h4" color="secondary">
          .
        </Typography>
      </Typography>
      <Box maxWidth="1200px" width="100%" mx="auto">
        <Slider logos={logos} />
      </Box>
    </Box>
  )
}

export default LogoSlider

LogoSlider.propTypes = {
  data: PropTypes.object.isRequired,
}

const Slider = ({ logos }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centered: true,
          speed: 4000,
          autoplay: true,
          autoplaySpeed: 0,
          cssEase: "linear",
          variableWidth: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centered: true,
          speed: 4000,
          autoplay: true,
          autoplaySpeed: 0,
          cssEase: "linear",
          variableWidth: true,
        },
      },
    ],
  }

  const imageStyle = {
    maxWidth: "100px",
    maxHeight: "100%",
    objectFit: "contain",
  }

  return (
    <Box maxWidth="100vw" overflow="hidden">
      <SlickSlider {...settings}>
        {logos.map((logo, index) => (
          <LogoContainer key={logo.src.id + index}>
            <img
              src={logo.src.childImageSharp.gatsbyImageData.images.fallback.src}
              style={imageStyle}
              alt={logo.alt}
            />
          </LogoContainer>
        ))}
      </SlickSlider>
    </Box>
  )
}

const LogoContainer = ({ children }) => (
  <Box
    width="100%"
    height="70px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    mx={{ base: 4, md: 0 }}
  >
    {children}
  </Box>
)
