import React from "react"

import { Box, List, ListItem, Stack, Typography } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"
import LocalizedLink from "../components/LocalizedLink"

const Footer = ({ footer_items }) => {
  const { title, menu_columns, bottom_links } = footer_items
  return (
    <Box
      component="footer"
      width="100vw"
      position="relative"
      left="50%"
      right="50%"
      ml="-50vw"
      mr="-50vw"
      bgcolor="#000065"
    >
      <Box maxWidth="1200px" width="100%" py={4} px={2} mx="auto">
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          borderBottom="1px solid rgba(128, 128, 182, 0.4)"
          pb={2}
          mb={5}
        >
          {/* Logo Side */}
          <Box>
            <LocalizedLink to="/">
              <StaticImage
                src="../../static/assets/logo-white.png"
                alt="Cyberbrief Logo White"
                style={{ maxWidth: "200px", marginBottom: "2rem" }}
              />
            </LocalizedLink>
            <Typography
              fontSize="1.25rem"
              lineHeight="1.5rem"
              fontWeight="bold"
              color="#fff"
              mb={4}
            >
              {title}
            </Typography>
            <StaticImage
              src="../../static/assets/logo-ministere-culture.png"
              alt="Logo du ministère de la Culture"
              style={{ maxWidth: "150px" }}
            />
          </Box>
          {/* Menu Side */}
          <Stack
            direction="row"
            color="#fff"
            flexWrap="wrap"
            pt={{ base: 4, md: 2 }}
            flexShrink={"0"}
            ml={{ md: 2 }}
          >
            {menu_columns.map((column, index) => {
              return (
                <Box
                  key={`menu_column_${index}`}
                  ml={index !== 0 && { md: 8 }}
                  mr={{ base: 6, md: 0 }}
                >
                  <List>
                    {column.links.map((item, i) => (
                      <LocalizedLink
                        key={`menu_item_${index}_${i}`}
                        to={item.link}
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        <MenuItem>{item.title}</MenuItem>
                      </LocalizedLink>
                    ))}
                  </List>
                </Box>
              )
            })}
          </Stack>
        </Stack>
        <Stack
          direction={{ base: "column-reverse", md: "row" }}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography
            color="#fff"
            fontSize="0.9rem"
            flexShrink="0"
            mt={{ base: 4, md: 0 }}
            mb={{ base: 2, md: 0 }}
            textAlign={{ base: "center", md: "left" }}
            width={{ base: "100%", md: "auto" }}
          >
            Copyright Cyberbrief, {new Date().getFullYear()}
          </Typography>
          <Box>
            <List
              sx={{
                display: "flex",
                direction: "row",
                justifyContent: { base: "center", md: "flex-end" },
                alignItems: "center",
                flexWrap: "wrap",
                py: 0,
                "&> *": { px: 2 },
                li: { pt: 0, pb: 2 },
              }}
            >
              {bottom_links.map((item, index) => {
                return (
                  <LocalizedLink
                    id={`menu_item_${index}`}
                    key={`menu_item_${index}`}
                    to={item.link}
                    style={{
                      color: "#fff",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    <MenuItem>{item.title}</MenuItem>
                  </LocalizedLink>
                )
              })}
            </List>
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}

const MenuItem = ({ children }) => (
  <ListItem disableGutters style={{ fontSize: "0.9rem" }}>
    {children}
  </ListItem>
)

export default Footer
